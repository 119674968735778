<template>
  <div class="page-not-found">
    <div class="page-not-found__message">
      <p class="page-not-found-title">Oops...</p>
      <div class="page-not-found-imagen">
        <div class="page-not-found-number">
          <span>4</span>
          <span class="page-not-found-number__diferent">0</span>
          <span>4</span>
        </div>
      </div>
      <h2 class="page-not-found-text">
        {{ $t("pageNotFound.message.first") }} <br />
        {{ $t("pageNotFound.message.second") }}
      </h2>
      <button class="page-not-found-button" @click="changeView()">
        <strong>{{ $t("pageNotFound.button") }}</strong>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  components: {},
  methods: {
    async changeView() {
      const baseUrl = window.location.origin;
      window.location.href = `${baseUrl}/home`;
    },
  },
};
</script>

<style lang="scss" scoped>
.page-not-found {
  width: 50%;
  margin: auto;
  padding: 20px 20px;
  @extend %flex-col;
  font-family: $font-family-portal;
  color: $color-primary-company;
}
.page-not-found-title {
  font-size: 50px;
  font-weight: bold;
  margin: 0px;
}
.page-not-found-imagen {
  background-image: url("https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif");
  height: 420px;
  width: 100%;
  background-position: center;
}
.page-not-found-number {
  @extend %flex-center-center;
  font-size: 100px;
  &__diferent {
    color: $color-blue-hover;
  }
}
.page-not-found-text {
  font-size: 20px;
}
.page-not-found-button {
  @include primary-button($heightSize: 50px);
  width: auto;
  font-size: 16px;
  padding: 15px 45px;
  margin: 20px 0px;
}
///****Responsive styles****\\\\
@media (max-width: 840px) {
  .page-not-found {
    width: 60%;
  }
}
@media (max-width: 720px) {
  .page-not-found {
    width: 70%;
  }
}
@media (max-width: 610px) {
  .page-not-found {
    width: 80%;
  }
}
@media (max-width: 515px) {
  .page-not-found {
    width: 90%;
  }
}
@media (max-width: 450px) {
  .page-not-found {
    width: 100%;
  }
}
</style>
